@import 'node_modules/bootstrap/scss/bootstrap-grid.scss';
$font: Roboto;
$font-path: "/assets/lamborghini/fonts/";
$format: "ttf";

@font-face {
  font-family: $font;
  src: url($font-path + $font + "-Light." + $format);
  font-weight: 300;
}

@font-face {
  font-family: $font;
  src: url($font-path + $font + "-Regular." + $format);
  font-weight: normal;
}

@font-face {
  font-family: $font;
  src: url($font-path + $font + "-Bold." + $format);
  font-weight: 700;
}

@font-face {
  font-family: $font;
  src: url($font-path + $font + "-Medium." + $format);
  font-weight: 500;
}





h1.main-caption {
  font-size: 1.8rem;

  @include media-breakpoint-only(md) {
    font-size: 1.8rem;
  }

  @include media-breakpoint-only(lg) {
    font-size: 2.5rem;
  }

  @include media-breakpoint-only(xl) {
    font-size: 3.625rem;
  }
}