@import 'variables.scss';
$theme-colors: (
    "primary": $primary,
    "secondary": $secondary,
    "light": $white,
    "dark": $black
);

.notification a.notice-link {
    color: $white;
    font-weight: bold;
    text-decoration: underline !important;
}